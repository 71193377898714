app.controller('FormController', [
    'FormsService',
    'FormsDataService',
    'UtilsService',
    'CampaignsService',
    'EmailTemplatesService',
    'UserService',
    'FileUploader',
    '$rootScope', '$scope', '$timeout', '$stateParams', '$filter', 'moment', 'toaster', '$state', '$http',
    function (Forms,
              FormsData,
              Utils,
              Campaigns,
              Email,
              Users,
              FileUploader,
              $rootScope, $scope, $timeout, $stateParams, $filter, moment, toaster, $state, $http) {

        $scope.$on('$destroy', function () {
            //TODO
        });

        /******************************************************************/

        var vm = this;
        var form_config_data = [];
        var form_data = {};
        var form_submit_return_data = null;
        var uploader = null;
        var form_config = null;
        var form_type = null;

        if ($scope.form_config) {
            form_config = $scope.form_config;
            form_type = $scope.form_config.type;

            form_config_data[form_type] = form_config;
        }

        $scope.$on('loadForm', function (event, args) {
            form_config = args.form_config;
            form_type = args.form_config.type;

            form_config_data[form_type] = form_config;

            loadForm(args.form_config);
        });

        // wait for form_config to populate and show form
        $scope.$watch('form_config', function (newVal, oldVal) {
            if (newVal != null) {
                loadForm(newVal);
            }
        }, true);

        /******************************************************************/

        vm.form = {};
        vm.appform = {};
        vm.accordion_collapse = [];
        vm.loadedForm = false;

        vm.temp_upload_url = null;

        // validation patterns used for text fields
        vm.patterns = {
            digits: /^\d*$/,
            patterns: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        }

        // unsaved check
        /**if (form_config && form_config.unsaved_check) {
         unsavedCheck();
         }**/

        vm.reset_form = function (form_type) {
            resetForm(form_type);
            loadForm(form_config_data[form_type]);
        }

        vm.resetSearchForm = function (form_type, refresh_list) {

            if (form_type == form_config.type) {

                resetForm(form_type);
                loadForm(form_config);

                if (typeof refresh_list == "undefined"){
                    refresh_list = true;
                }

                $rootScope.$broadcast('closeSearch', {type: form_type});
                $rootScope.$broadcast('searchUpdated', {
                    type: form_type,
                    refresh_list: refresh_list
                });
            }
        }

        $scope.$on('submitSearchForm', function (e) {
            if (vm.form[form_type].data.sub_type == 'search') {
                vm.submit();
            }
        })

        $scope.$on('resetSearchForm', function (event, args) {
            if (args.form_type) {

                vm.resetSearchForm(args.form_type, args.refresh_list);
            }
        })

        $scope.$on('resetForm', function (event, args) {
            if (args && args.type == form_config.type){

                vm.form[form_type].state.status = false;
            }
        });

        $scope.$on('reloadForm', function (event, args) {
            loadForm(args.form_config);
        });

        $scope.$on('reloadAfterUpdate', function (event, args) {
            vm.reset_form(args.type)
        })

        /**$scope.$on('updateForm', function (e) {
         form_config = $scope.$parent.form_config;
         form_type = form_config.type;
         loadForm(form_config);
         });**/

        /**$scope.$on('addForm', function (e) {
         form_config = $scope.$parent.form_config;
         form_type = form_config.type;
         //resetForm(form_type);
         loadForm(form_config);
         });**/

        // custom emitters
        // Show add faculty after submit
        /*vm.emitShowFaculty = function () {
            vm.form[form_type].state.status = false;
            vm.form[form_type].state.message = '';
            $rootScope.$broadcast('showFacultyAfterAdd');
        }**/

        // Show add section/presentation/secondary node to an event
        /**vm.emitAddChildNode = function () {
         vm.form[form_type].state.status = false;
         vm.form[form_type].state.message = '';
         $rootScope.$broadcast('eventsAddChildNode');
         }**/

        // Show conference add faculty after submit
        vm.emitShowConferenceFaculty = function () {
            vm.form[form_type].state.status = false;
            vm.form[form_type].state.message = '';
            $rootScope.$broadcast('showConferenceFacultyAfterAdd');
        }

        // Show add event after submit
        vm.emitShowEvent = function () {
            vm.form[form_type].state.status = false;
            vm.form[form_type].state.message = '';
            $rootScope.$broadcast('showEventAfterAdd');
        }

        // Show add section after event add
        vm.emitAddSectionNode = function () {
            $rootScope.$broadcast('eventsAddSectionNode');
        }

        // Show add section
        /**vm.emitAddAnotherSectionNode = function () {
         $rootScope.$broadcast('eventsAddAnotherSectionNode');
         }**/

        // reset external user search form
        vm.emitResetExternalSearch = function () {
            $rootScope.$broadcast('usersResetExternalSearch');
        }

        // add user as new after searching external users
        vm.emitAddAsNew = function () {
            $rootScope.$broadcast('usersAddAsNew');
        }

        // Notify form has loaded
        function emitFormLoaded() {
            $rootScope.$broadcast('formLoaded', {type: form_type});
            vm.loadedForm = true;
        }

        vm.getFieldName = function (field_name) {
            return (form_data[form_type].form_fields[field_name]) ? form_data[form_type].form_fields[field_name].title : '';
        }

        vm.getFieldErrorMsg = function (field_name, rule_type) {
            return form_data[form_type].form_fields[field_name]['rules'][rule_type].rule_msg;
        }

        // Display template based on field type
        vm.getFieldTemplate = function (template) {

            switch (template) {
                case "select":
                case "select_multiple":
                    return "field.select";
                    break;
                case "text":
                case "single_text":
                    return "field.text";
                    break;
                case "single_radio":
                    return 'field.radio';
                    break;
                case "single_radio2":
                    return 'field.radio2';
                    break;
                case "date":
                case "datetime":
                    return 'field.datetime';
                    break;
                case "multiple_file_upload" :
                case "file_upload" :
                    return 'field.file_upload';
                    break;
                default:
                    return 'field.'+template;
            }
        }

        function loadForm(form_config) {

            form_data[form_config.type] = {
                form_fields: {},
                dependent_fields: [],
                hide_on_fields: [],
                watch_field_model: null
            }

            vm.form[form_config.type] = {
                data: {},
                field_model: {},
                field_options: {},
                field_settings: {},
                field_show: {},
                show_submit: false,
                show_cancel: false,
                submit_text: 'Submit',
                state: {
                    status: false,
                    message: '',
                },
                cache_timestamp: Date.now()
            }

            // if form was defined in a controller
            if (form_config.hasOwnProperty('form_data')) {
                setFormData(form_config.form_data);
            } else {
                // get form from db
                Forms.getForm(form_config).then(function (data) {

                    if (data && data.success) {
                        setFormData(data)
                    } else {
                        toaster.pop('error', 'Error', 'Form not found.');
                    }
                });
            }
        }

        function setFormData(data) {

            vm.form[form_type].data = data.form;

            // combine all fields into an array
            if (vm.form[form_type].data.fieldsets) {
                angular.forEach(vm.form[form_type].data.fieldsets, function (fieldset, key) {
                    angular.forEach(fieldset.fields, function (field, key) {

                        form_data[form_type].form_fields[field.name] = field;

                        if (field.hasOwnProperty('sub_fields') && field.sub_fields.length) {

                            angular.forEach(field.sub_fields, function (field, key) {
                                form_data[form_type].form_fields[field.name] = field;
                            });
                        } else {
                            form_data[form_type].form_fields[field.name] = field;
                        }
                    });
                });
            } else {
                angular.forEach(vm.form[form_type].data.fields, function (field, key) {
                    if (field.hasOwnProperty('sub_fields')) {
                        form_data[form_type].form_fields[field.name] = field;

                        if (field.sub_fields.length) {
                            angular.forEach(field.sub_fields, function (field, key) {
                                form_data[form_type].form_fields[field.name] = field;
                            });
                        }
                    } else {
                        form_data[form_type].form_fields[field.name] = field;
                    }
                });
            }

            // set default field values/options
            angular.forEach(form_data[form_type].form_fields, function (field) {
                setModels(field);

                // adds autocomplete="on" rule for single text
                // fields if it's not provided in the rules
                // returned by endpoint
                if (field.template == "single_text") {
                    var autocomplete_rule = {
                        "rule_type": "autocomplete",
                        "rule_value": "off",
                        "rule_msg": ""
                    };
                    if(!field.rules) {
                        field.rules = {
                            'autocomplete': autocomplete_rule
                        };

                    } else {
                        if(!field.rules.autocomplete) {
                            field.rules.autocomplete = autocomplete_rule;
                        }
                    }
                }
            });

            // if form is 'readonly' set all fields to 'readonly'
            if (form_config.mode == 'readonly') {
                disableAllFields();
            }

            // set defaults based on dependent fields
            angular.forEach(form_data[form_type].form_fields, function (field) {
                setDependentDefaults(field);
            });

            // set options for enabled/disabled fields
            form_data[form_type].form_enable_fields = [];
            form_data[form_type].form_disable_fields = [];

            angular.forEach(form_data[form_type].form_fields, function (field) {
                setEnableDisableDefaults(field);
            });

            // watch field model for changes and update any dependent fields that changes, or enable/disable fields
            form_data[form_type].watch_field_model = $scope.$watch(function () {
                return vm.form[form_type].field_model
            }, function (check_new_val, check_old_val) {

                if (form_config.mode != 'readonly') {
                    enableDisableFields();

                    // check for dependent fields
                    angular.forEach(form_data[form_type].dependent_fields, function (depends, key) {

                        var new_field_val = check_new_val[depends.field_name_to_watch];
                        var old_field_val = check_old_val[depends.field_name_to_watch];


                        if (!angular.equals(new_field_val, old_field_val)) {

                            // set new options
                            var field_to_watch = form_data[form_type].form_fields[depends.field_name_to_watch]
                            var field_to_update = form_data[form_type].form_fields[depends.field_name_to_update]
                            var depend_field = vm.form[form_type].field_model[field_to_watch.name];

                            switch (field_to_update.params.type){
                                case 'single' :

                                    // reset model - remove values selected via previous parent values
                                    if (!angular.isUndefined(old_field_val)) {
                                        vm.form[form_type].field_model[depends.field_name_to_update] = null;
                                    }

                                    // add updated options to field
                                    if (typeof depend_field !== "undefined" && depend_field != null && depend_field.selected) {

                                        vm.form[form_type].field_options[field_to_update.name] = field_to_update.options[vm.form[form_type].field_model[field_to_watch.name].selected.option_value];
                                    } else {
                                        vm.form[form_type].field_options[field_to_update.name] = field_to_update.options[vm.form[form_type].field_model[field_to_watch.name]];
                                    }
                                    break;
                                case 'multiple' :

                                    var new_to_update_field_options = []
                                    var selected_field_values = []

                                    // determine the selected options from the parent/field_to_watch
                                    angular.forEach(vm.form[form_type].field_model[field_to_watch.name].selected, function (value, key){
                                        selected_field_values.push(value.option_value)
                                    })

                                    // filter options based on parent_title of dependent field
                                    angular.forEach(field_to_update.options, function (value, key){
                                        if (selected_field_values.includes(value.parent_id)) {
                                            new_to_update_field_options.push(value)
                                        }
                                    })

                                    // check if any parent fields were removed and remove child values
                                    // since this is a multiple select, there might still remain valid parent values
                                    // and their selected children need to be preserved in the model
                                    var updated_field_model = []
                                    angular.forEach(vm.form[form_type].field_model[depends.field_name_to_update].selected, function (value, key){
                                        if (selected_field_values.includes(value.parent_id)) {
                                            updated_field_model.push(value)
                                        }
                                    })
                                    vm.form[form_type].field_model[depends.field_name_to_update].selected = updated_field_model;

                                    // add updated options to field
                                    vm.form[form_type].field_options[field_to_update.name] = new_to_update_field_options;
                                    break;
                            }
                        }
                    });

                    // check for hide_on fields
                    angular.forEach(form_data[form_type].hide_on_fields, function (fld, key) {

                        if (typeof vm.form[form_type].field_model[fld.field_name_to_watch] !== "undefined"){
                            if(fld.field_value_to_watch.constructor === Array) {
                                if (fld.field_value_to_watch.includes(vm.form[form_type].field_model[fld.field_name_to_watch])) {
                                    vm.form[form_config.type].field_show[fld.field_name_to_hide] = false;
                                } else {
                                    vm.form[form_config.type].field_show[fld.field_name_to_hide] = true;
                                }
                            } else {
                                if (vm.form[form_type].field_model[fld.field_name_to_watch] == fld.field_value_to_watch){
                                    vm.form[form_config.type].field_show[fld.field_name_to_hide] = false;
                                } else {
                                    vm.form[form_config.type].field_show[fld.field_name_to_hide] = true;
                                }
                            }
                        }
                    })
                }
            }, true);

            // show submit button
            vm.form[form_type].show_submit = (form_config.mode == 'readonly') ? false : true;

            // hide cancel button setting
            if (!form_config.hide_cancel) {
                vm.form[form_type].show_cancel = true;
            }

            // change 'Submit' button text
            if (form_config.submit_text) {
                vm.form[form_type].submit_text = form_config.submit_text;
            }

            // check if form has a sub type
            vm.form[form_type].data.sub_type = form_type.split(".")[1];

            switch (vm.form[form_type].data.sub_type) {
                case 'search' :

                    vm.form[form_type].data.field_rows = [];
                    vm.form[form_type].data.fieldset_rows = [];

                    var columns = 2;

                    // split fieldsets into columns
                    if (vm.form[form_type].data.fieldsets && vm.form[form_type].data.fieldsets.length) {
                        for (var i = 0; i < vm.form[form_type].data.fieldsets.length; i += columns) {
                            var fieldset_copy = angular.copy(vm.form[form_type].data.fieldsets.slice(i, i + columns))
                            vm.form[form_type].data.fieldset_rows.push(fieldset_copy);
                        }
                    } else {
                        // split fields into columns
                        for (var i = 0; i < vm.form[form_type].data.fields.length; i += columns) {
                            var field_copy = angular.copy(vm.form[form_type].data.fields.slice(i, i + columns))
                            vm.form[form_type].data.field_rows.push(field_copy);
                        }
                    }

                    break;
                default :
                    // default to 'standard' sub type
                    vm.form[form_type].data.sub_type = 'standard';
            }

            emitFormLoaded();
        }

        function disableAllFields() {
            angular.forEach(form_data[form_type].form_fields, function (field, key) {
                if (field.hasOwnProperty('params') && field.params != null) {
                    field.params.readonly = true;
                } else {
                    field.params = {
                        readonly: true
                    }
                }
            });
        }

        function enableAllFields() {
            angular.forEach(form_data[form_type].form_fields, function (field, key) {

                if (field.hasOwnProperty('params') && field.params != null) {
                    if (!field.params.hasOwnProperty('readonly')) {
                        field.params.readonly = false;
                        field.params.readonly_msg = null;
                    }

                } else {
                    field.params = {
                        readonly: false,
                        readonly_msg: null,
                    }
                }
            });
        }

        // set field models
        function setModels(field) {

            // init field settings property
            vm.form[form_type].field_settings[field.name] = {}

            switch (field.template) {
                case 'single_radio' :
                    // set last checked in order to deselect a radio button selection
                    vm.form[form_type].field_settings[field.name].last_checked = null;

                    break;
                case 'checkbox' :
                    vm.form[form_type].field_model[field.name] = {};

                    angular.forEach(field.options, function (option, key) {
                        if (angular.isDefined(option.selected) && option.selected === 1) {
                            vm.form[form_type].field_model[field.name][option.option_value] = option.option_value.toString();
                        } else {
                            vm.form[form_type].field_model[field.name][option.option_value] = '';
                        }
                    });

                    break;
                case 'select':

                    vm.form[form_type].field_model[field.name] = {
                        'selected': null
                    };

                    // update model with options from db

                    if (field.params.depends_on) {

                        switch (field.params.type) {
                            case 'single' :
                                var select_options = null;

                                angular.forEach(field.options, function (option_group) {
                                    angular.forEach(option_group, function (option) {
                                        if (angular.isDefined(option.selected) && option.selected === 1) {
                                            select_options = option;
                                        }
                                    });
                                });
                                break;
                            case 'multiple' :
                                var select_options = [];

                                angular.forEach(field.options, function (option) {
                                    if (angular.isDefined(option.selected) && option.selected === 1) {
                                        select_options.push(option);
                                    }
                                });
                                break;
                        }

                    } else {
                        // set selected dropdown option
                        switch (field.params.type) {
                            case 'single' :
                                // check if any options are set as selected
                                angular.forEach(field.options, function (option, key) {
                                    if (angular.isDefined(option.selected) && option.selected === 1) {
                                        select_options = option;
                                    }
                                });

                                // set the default option
                                if (!select_options) {
                                    var single_sel_val = form_data[form_type].form_fields[field.name].value ? form_data[form_type].form_fields[field.name].value : form_data[form_type].form_fields[field.name].default;

                                    angular.forEach(field.options, function (option, key) {
                                        if (option.value == single_sel_val) {
                                            select_options = option;
                                        }
                                    });
                                }

                                break;
                            case 'multiple' :
                                var select_options = [];
                                angular.forEach(field.options, function (option, key) {
                                    if (angular.isDefined(option.selected) && option.selected === 1) {
                                        select_options.push(option);
                                    }
                                });

                                break;
                        }
                    }

                    vm.form[form_type].field_model[field.name].selected = select_options;

                    break;
                case 'date':
                case 'datetime':

                    var display_date_format = (field.template == 'datetime') ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD';

                    // add date range allowed to select
                    if (field.rules.between_dates) {
                        var between_dates = field.rules.between_dates.rule_value.split(',');

                        field.minDate = moment(between_dates[0]);
                        field.maxDate = moment(between_dates[1]);

                        if (field.value){
                            // check if date within between dates rule bounds, reset if not
                            var default_date = moment(field.value);

                            if (default_date.isBefore(field.minDate) || default_date.isAfter(field.maxDate)){
                                field.value = null;
                            }
                        }
                    }

                    // init model
                    vm.form[form_type].field_model[field.name] = '';

                    // set read-only mode date view
                    if (field.value && form_config.mode == 'readonly') {
                        vm.form[form_type].field_model[field.name] = moment.parseZone(field.value).format(display_date_format);
                    }

                    if (field.value){
                        var default_date = field.value.indexOf('T') != -1 ? moment.parseZone(field.value) : moment(field.value);
                    } else {
                        var default_date = false;
                    }

                    var datepicker_options = {
                        format: display_date_format,
                        stepping: 5,
                        minDate: field.minDate ? field.minDate : false,
                        maxDate: field.maxDate ? field.maxDate : false,
                        sideBySide: false,
                        defaultDate: default_date,
                        ignoreReadonly: true,
                        showClear: true,
                        showClose: true,
                        allowInputToggle: true,
                        useCurrent: false,
                        toolbarPlacement: 'top'
                    }

                    if (field.template == 'datetime'){
                        datepicker_options.sideBySide = true;
                    }

                    vm.form[form_type].field_settings[field.name] = {
                        datepicker_options: datepicker_options
                    }

                    break;
                case 'rich_text':

                    // TODO: start email templates field =============
                    // get email templates
                    vm.email_template = null;
                    if (form_type == 'campaigns') {
                        Campaigns.getEmailTemplates({
                            conference_id: form_config.conference_id,
                            campaign_type_id: form_config.campaign_type_id
                        }).then(function (data) {
                            if (data && data.success) {
                                // prepend "Global: " to campaign type name for global templates
                                // for better grouping in form field
                                angular.forEach(data.templates.data, function (value, key) {
                                    if (value.is_global === 1) {
                                        value.campaign_type = 'Global: ' + value.campaign_type;
                                    }
                                });
                                vm.email_templates = data.templates.data;
                            }
                        });
                    }

                    if (form_type == 'global_email_templates') {
                        Email.getGlobalEmailTemplates().then(function (data) {
                            if (data && data.success) {
                                // prepend "Global: " to campaign type name for global templates
                                // for better grouping in form field
                                angular.forEach(data.templates.data, function (value, key) {
                                    if (value.is_global === 1) {
                                        value.campaign_type = 'Global: ' + value.campaign_type;
                                    }
                                });
                                vm.email_templates = data.templates.data;
                            }
                        });
                    }
                    // TODO: end email templates field =============

                    var all_new_buttons = ' | ';

                    angular.forEach(field.extra.replacements, function (value, key) {
                        all_new_buttons = all_new_buttons + ' ' + key + '_button';
                    });

                    vm.form[form_type].field_settings[field.name].tinymce_options = {
                        setup: function (editor) {
                            editor.on("init", function () {

                            });

                            var strt = '{:';
                            var stop = ':}';
                            var bgins = 'ffc300';

                            angular.forEach(field.extra.replacements, function (value, key) {
                                var replacements = [];

                                angular.forEach(value, function (value2) {
                                    replacements.push({
                                        'text': value2.toUpperCase(),
                                        'value': value2.toUpperCase()
                                    })
                                })

                                editor.addButton(key + '_button', {
                                    type: 'listbox',
                                    text: key,
                                    icon: false,
                                    onselect: function (e) {
                                        editor.insertContent(strt + this.value().toUpperCase() + stop);
                                        //editor.insertContent('<span style="display: inline; font-weight: bold;" class="mceNonEditable">' + strt + this.value() + stop + '</span>');
                                        //editor.insertContent('<span style="display: inline; font-weight: bold; background-color: #' + bgins + '">' + strt + this.value() + stop + '</span><span style="display: inline; background-color: #ffffff; min-width: 2px;"> </span> ');
                                        this.value(null);
                                        this.text(key);
                                    },
                                    values: replacements,
                                    onPostRender: function () {
                                        this.text(key);
                                        this.value(null);
                                    }
                                });
                            })
                        },
                        inline: false,
                        menubar: false,
                        elementpath: false,
                        plugins: 'charmap,paste,link,autolink,autoresize,autosave,textcolor,noneditable',
                        paste_use_dialog: false,
                        paste_auto_cleanup_on_paste: true,
                        paste_convert_headers_to_strong: false,
                        paste_strip_class_attributes: "all",
                        paste_remove_spans: true,
                        paste_remove_styles: true,
                        paste_retain_style_properties: "",
                        entity_encoding: 'raw',
                        default_link_target: "_blank",
                        link_assume_external_targets: true,
                        link_title: true,
                        autosave_ask_before_unload: true,

                        skin: 'lightgray',
                        toolbar1: "charmap | forecolor | bold italic underline subscript superscript | bullist numlist | link | removeformat | pastetext " + all_new_buttons + " | template",
                        content_style: "{overflow-wrap: break-word; word-wrap: break-all;}",
                        theme: 'modern',
                        statusbar: false,
                        allow_conditional_comments: false,
                        invalid_elements: 'table,iframe,script,button,input'
                    }
                    break;
                case 'multiple_file_upload':
                case 'file_upload':

                    setUpload(field, form_config.id);

                    break;
                default:
                    vm.form[form_type].field_model[field.name] = '';

            }

            // get all fields that have dependent fields
            if (field.params != null && field.params.depends_on != undefined) {
                form_data[form_type].dependent_fields.push({
                    'field_name_to_watch': field.params.depends_on,
                    'field_name_to_update': field.name
                });
            }

            // get all fields that have hide_on
            if (field.params != null && field.params.hide_on != undefined && field.params.hide_on) {

                var field_watch = field.params.hide_on.field;

                if (field.params.hide_on.field.indexOf('.') > -1){
                    field_watch = field.params.hide_on.field.split('.');
                    field_watch = field_watch[1];
                }

                form_data[form_type].hide_on_fields.push({
                    'field_name_to_watch': field_watch,
                    'field_value_to_watch' : field.params.hide_on.value,
                    'field_name_to_hide': field.name
                });
            }

            vm.form[form_type].field_show[field.name] = field.show;
        }

        function setUploadUrl(id) {

            switch (form_type) {
                case 'campaigns' :
                    file_upload_url = '/campaigns/' + id + '/upload';
                    break;

                case 'conferences' :
                    file_upload_url = '/conferences/' + id;
                    break;

                case 'faculty' :
                case 'tasks.profile_confirmation' :
                    file_upload_url = '/faculty/' + id + '/profile_photo';
                    break;
            }

            return file_upload_url;
        }

        function setUpload(field, id) {

            var file_upload_filters = [];
            var file_upload_name = null;
            var file_upload_url = null;


            // filters
            // queue limit filter
            var file_upload_filter_queue_limit = {
                name: 'queueLimitFilter',
                msg: 'Maximum file limit reached.',
                fn: function (item /*{File|FileLikeObject}*/, options) {
                    return this.queue.length < 10;
                }
            }

            // image filter
            var file_upload_filter_images = {
                name: 'imageFilter',
                msg: 'Invalid image file type.',
                fn: function (item /*{File|FileLikeObject}*/, options) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                }
            }

            // attachments filter
            var file_upload_filter_attachments = {
                name: 'attachmentsFilter',
                msg: 'Invalid attachment file type.',
                fn: function (item /*{File|FileLikeObject}*/, options) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    return '|jpg|png|jpeg|bmp|gif|vnd.openxmlformats-officedocument.presentationml.presentation|vnd.openxmlformats-officedocument.wordprocessingml.document|vnd.openxmlformats-officedocument.presentationml.template|pdf|doc|docx|ppt|pptx|potx|'.indexOf(type) !== -1;
                }
            }

            // TODO: update to use configurable upload url
            // config vars based on form type
            switch (form_type) {
                case 'campaigns' :
                    file_upload_filters.push(file_upload_filter_attachments);
                    file_upload_filters.push(file_upload_filter_queue_limit);
                    file_upload_url = setUploadUrl(id);
                    file_upload_name = 'file';

                    break;

                case 'conferences' :
                    file_upload_filters.push(file_upload_filter_images);
                    file_upload_filters.push(file_upload_filter_queue_limit);
                    file_upload_url = setUploadUrl(id);
                    file_upload_name = 'banner_img';

                    break;

                case 'faculty' :
                    file_upload_filters.push(file_upload_filter_images);
                    file_upload_filters.push(file_upload_filter_queue_limit);
                    file_upload_url = setUploadUrl(id);
                    file_upload_name = 'profile_img';

                    break;

                case 'tasks.profile_confirmation' :
                    file_upload_filters.push(file_upload_filter_images);
                    file_upload_filters.push(file_upload_filter_queue_limit);
                    file_upload_url = setUploadUrl(form_config.faculty_id);
                    file_upload_name = 'profile_img';

                    break;
            }

            // file upload options
            uploader = $scope.uploader = new FileUploader({
                'url': file_upload_url,
                'alias': file_upload_name,
                'filters': file_upload_filters,
                'formData': []
            });

            // CALLBACKS
            uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {
                //console.info('onWhenAddingFileFailed', item, filter, options);

                toaster.pop('error', 'Error', filter.msg + ' <br>' + item.name);
                vm.form[form_type].field_settings[field.name].files_uploaded = true;
            };
            uploader.onAfterAddingFile = function (fileItem) {

                vm.form[form_type].field_settings[field.name].files_uploaded = true;

                if (form_data[form_type].form_fields[field.name].params.resize_width && form_data[form_type].form_fields[field.name].params.resize_height) {

                    $scope.cropped = {
                        image: '',
                    };
                    $scope.crop_source = {
                        image: ''
                    }

                    var reader = new FileReader();
                    reader.onload = function (event) {
                        $scope.$apply(function () {
                            $scope.crop_source.image = event.target.result;
                        });
                    };
                    reader.readAsDataURL(fileItem._file);
                }

            };
            uploader.onAfterAddingAll = function (addedFileItems) {
                //console.info('onAfterAddingAll', addedFileItems);
                vm.form[form_type].field_settings[field.name].files_uploaded = true;

            };
            uploader.onBeforeUploadItem = function (item) {
                //item.formData.push({id: 'data'});
                //console.info('onBeforeUploadItem', item);

                /**
                 * Converts data uri to Blob. Necessary for uploading.
                 * @see
                 *   http://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
                 * @param  {String} dataURI
                 * @return {Blob}
                 */
                var dataURItoBlob = function (dataURI) {
                    // convert base64/URLEncoded data component to raw binary data held in a string
                    var byteString;
                    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
                        byteString = atob(dataURI.split(',')[1]);
                    } else {
                        byteString = decodeURI(dataURI.split(',')[1]);
                    }
                    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
                    var array = [];
                    for (var i = 0; i < byteString.length; i++) {
                        array.push(byteString.charCodeAt(i));
                    }
                    return new Blob([new Uint8Array(array)], {type: mimeString});
                };

                if (form_data[form_type].form_fields[field.name].params.resize_width && form_data[form_type].form_fields[field.name].params.resize_height) {
                    var blob = dataURItoBlob($scope.cropped.image);
                    item._file = blob;
                }

                if (vm.temp_upload_url) {
                    item.url = vm.temp_upload_url;
                }
            };
            uploader.onProgressItem = function (fileItem, progress) {
                //console.info('onProgressItem', fileItem, progress);
            };
            uploader.onProgressAll = function (progress) {
                //console.info('onProgressAll', progress);
            };
            uploader.onSuccessItem = function (fileItem, response, status, headers) {
                //console.info('onSuccessItem', fileItem, response, status, headers);
            };
            uploader.onErrorItem = function (fileItem, response, status, headers) {
                vm.form[form_type].field_settings[field.name].files_uploaded = false;
                //console.info('onErrorItem', fileItem, response, status, headers);
            };
            uploader.onCancelItem = function (fileItem, response, status, headers) {
                //console.info('onCancelItem', fileItem, response, status, headers);
            };
            uploader.onCompleteItem = function (fileItem, response, status, headers) {
                //console.info('onCompleteItem', fileItem, response, status, headers);
            };
            uploader.onCompleteAll = function () {
                //console.info('onCompleteAll');

                // if no errors on file upload, proceed to complete form submit
                if (vm.form[form_type].field_settings[field.name].files_uploaded) {

                    vm.temp_upload_url = null;

                    switch (form_type) {
                        case 'campaigns' :
                            uploader.clearQueue();
                            $rootScope.$broadcast('formUpdated', {type: 'campaigns'});

                            break;
                        default:
                            completeFormSubmit(form_submit_return_data);

                            break;
                    }

                } else {
                    toaster.pop('error', 'Error', 'File uploading errors. Please review and try again.');
                }
            };

        }

        // set enable/disable fields
        function setEnableDisableDefaults(field) {
            if (field.hasOwnProperty('options') && field.options.length) {
                form_data[form_type].form_enable_fields[field.name] = [];
                form_data[form_type].form_disable_fields[field.name] = [];

                angular.forEach(field.options, function (value, key) {

                    if (value.hasOwnProperty('option_enable_field')) {
                        var obj = {};
                        obj[value.option_value] = value.option_enable_field;
                        form_data[form_type].form_enable_fields[field.name].push(obj);
                    }

                    if (value.hasOwnProperty('option_disable_field')) {
                        var obj = {};
                        obj[value.option_value] = value.option_disable_field;
                        form_data[form_type].form_disable_fields[field.name].push(obj);
                    }
                })
            }
        }

        function enableDisableFields() {

            // reset all fields
            enableAllFields();

            // check for enable/disable fields
            angular.forEach(form_data[form_type].form_fields, function (field, field_key) {

                // if disable options were set for this field
                if (form_data[form_type].form_disable_fields[field.name] && form_data[form_type].form_disable_fields[field.name].length) {
                    // get field value
                    var field_value = null;

                    // determine value based on template used
                    // TODO: refactor in a more dynamic way
                    switch (field.template) {
                        case 'select' :
                            // single selection field
                            if (field.params.type == 'single') {
                                if (
                                    vm.form[form_type].field_model[field.name] &&
                                    vm.form[form_type].field_model[field.name].selected &&
                                    vm.form[form_type].field_model[field.name].selected.option_value != null
                                ) {
                                    field_value = vm.form[form_type].field_model[field.name].selected.option_value;
                                } else if (vm.form[form_type].field_model[field.name] && !Array.isArray(vm.form[form_type].field_model[field.name].selected)) {
                                    field_value = vm.form[form_type].field_model[field.name].selected;
                                }
                            }

                            break;
                        default:
                            break;

                    }

                    // disable fields based on controlling field value
                    if (field_value) {
                        angular.forEach(form_data[form_type].form_disable_fields[field.name], function (value1, key1) {
                            angular.forEach(value1, function (value2, key2) {

                                if (key2.toString() === field_value.toString()) {
                                    angular.forEach(value2, function (value3, key3) {
                                        form_data[form_type].form_fields[value3].params.readonly = true;
                                        form_data[form_type].form_fields[value3].params.readonly_msg = 'TEST This field is disabled because the ' + field.title + ' settings are overriding this';
                                        form_data[form_type].form_fields[value3].params.readonly_msg = '(' + form_data[form_type].form_fields[value3].value + ') This field is disabled because the ' + field.title + ' settings are overriding this';
                                    })
                                }
                            })
                        })
                    }
                }
            })
        }

        // set dependent defaults
        function setDependentDefaults(field) {

            switch (field.template) {
                case 'select':

                    // set default field options if field depends on another field
                    if (field.params.depends_on) {

                        switch (field.params.type){
                            case 'single' :
                                var depend_field_value =
                                    form_data[form_type].form_fields[field.params.depends_on].value ?
                                        form_data[form_type].form_fields[field.params.depends_on].value :
                                        form_data[form_type].form_fields[field.params.depends_on].default;

                                vm.form[form_type].field_options[field.name] = field.options[depend_field_value];

                                break;
                            case 'multiple' :
                                //set default options on load in a field that is dependent on another field - multiple selection version

                                var new_to_update_field_options = []
                                var selected_field_values = []

                                // determine the selected options from the parent/field_to_watch
                                angular.forEach(vm.form[form_type].field_model[field.params.depends_on].selected, function (value, key){
                                    selected_field_values.push(value.option_value)
                                })

                                // filter options based on parent_title of dependent field
                                angular.forEach(field.options, function (value, key){
                                    if (selected_field_values.includes(value.parent_id)) {
                                        new_to_update_field_options.push(value)
                                    }
                                })

                                // add options to field
                                vm.form[form_type].field_options[field.name] = new_to_update_field_options;

                                break;
                        }

                    }

                    break;
                default:
            }
        }

        function completeFormSubmit(data) {
            //vm.appform[form_type].$setPristine();
            vm.form[form_type].state.status = true;
            vm.form[form_type].state.message = customSuccessMsg(data.message, data.data);

            $rootScope.$broadcast('formUpdated', {
                'type': form_type,
                'data': data,
                'action': (form_config.parent_id) ? 'add' : 'update'
            });

            if (form_config.okta_id) {
                vm.reset_form(form_type)
            }
        }

        function resetForm(form_type_reset) {

            if (form_type_reset == form_config.type) {
                vm.form[form_type_reset].state.status = false;
                vm.form[form_type_reset].state.message = '';
                vm.form[form_type_reset].field_model = {};

                form_data[form_type_reset].form_fields = {};

                // stop field_model watch
                form_data[form_type_reset].watch_field_model();

                // reset stored data
                FormsData.resetSearchData(form_type_reset);

                // remove okta_id
                if (form_config.okta_id) {
                    form_config.okta_id = null;
                }
            }
        }

        // build success message after form submitted
        function customSuccessMsg(msg, node) {
            var custom_msg = '';

            if (msg == 'Event created') {
                if (node.depth == 1) {
                    custom_msg = 'Section created';
                } else if (node.depth == 2 || node.depth == 3) {
                    custom_msg = 'Presentation created';
                }
            }
            if (msg == 'Event updated') {
                if (node.depth == 1) {
                    custom_msg = 'Section updated';
                } else if (node.depth == 2 || node.depth == 3) {
                    custom_msg = 'Presentation updated';
                }
            }
            if (custom_msg != '') {
                return custom_msg;
            } else {
                return msg;
            }
        }

        function unsavedCheck() {

            // catch navigation away from page and prompt to confirm
            $scope.$on('$stateChangeStart', function (event) {

                var answer = confirm("Are you sure you want to leave this page?")
                if (!answer) {
                    event.preventDefault();
                }

            });

            // catch page reload and prompt to confirm
            $(window).on('beforeunload', function (e) {

                var message = "Are you sure you want to leave this page?";
                var firefox = /Firefox[\/\s](\d+)/.test(navigator.userAgent);

                if (firefox) {
                    //Add custom dialog
                    //Firefox does not accept window.showModalDialog(), window.alert(), window.confirm(), and window.prompt() furthermore
                    var dialog = document.createElement("div");
                    document.body.appendChild(dialog);
                    dialog.id = "dialog";
                    dialog.style.visibility = "hidden";
                    dialog.innerHTML = message;
                    var left = document.body.clientWidth / 2 - dialog.clientWidth / 2;
                    dialog.style.left = left + "px";
                    dialog.style.visibility = "visible";
                    var shadow = document.createElement("div");
                    document.body.appendChild(shadow);
                    shadow.id = "shadow";
                    //tip with setTimeout
                    setTimeout(function () {
                        document.body.removeChild(document.getElementById("dialog"));
                        document.body.removeChild(document.getElementById("shadow"));
                    }, 0);
                }

                return message;
            });
        }

        // check if any of the option were selected if a field is required
        vm.someSelected = function (object, required_flag) {

            if (required_flag) {
                if (!angular.isUndefined(object)) {
                    return Object.keys(object).some(function (key) {
                        return object[key];
                    });
                }
            } else {
                return true;
            }
        }

        // type ahead search
        vm.searchField = function(search_url, search_param, search_value){
            var params = { params : {}};
            params.params[search_param] = search_value;

            return $http.get(search_url, params).then(function(response){
                return response.data.results;
            });
        }

        $scope.cities = function (cityName) {
            return $http.jsonp("https://gd.geobytes.com/AutoCompleteCity?callback=JSON_CALLBACK &filter=US&q=" + cityName).then(function (response) {
                //console.log(JSON.stringify(response));citty
                return response.data !== null && response.data.length === 1 && response.data[0] === '' ? [cityName] : response.data;
                //return limitToFilter(response.data, 15);
            });
        };

        // reset radio field
        vm.resetRadioField = function ($event, field) {

            if ($event.target.value === vm.form[form_type].field_settings[field.name].last_checked) {
                vm.form[form_type].field_model[field.name] = null;
                vm.form[form_type].field_settings[field.name].last_checked = null
            } else {
                vm.form[form_type].field_settings[field.name].last_checked = $event.target.value
            }
        }

        // reset button group field
        vm.resetButtonGroupField = function (value, field) {

            if (value == vm.form[form_type].field_model[field.name]) {
                vm.form[form_type].field_model[field.name] = null;
            } else {
                vm.form[form_type].field_model[field.name] = value;
            }
        }

        vm.updateEmailTemplate = function ($item, $event, field_name) {

            Campaigns.getEmailTemplate($item.id, null).then(function (data) {
                if (data && data.success) {
                    if (confirm('Replace current message body? All changes will be lost.')) {
                        vm.form[form_config.type].field_model[field_name] = data.template.body;
                    }
                    vm.email_template = null;
                }
            });
        }

        vm.autoSubmitSearch = function () {
            if (vm.form[form_type].data.sub_type == 'search') {
                vm.submit();
            }
        }

        /**
         * Save form
         */
        vm.submit = function () {

            $rootScope.alerts[form_type] = [];

            var form_submit_data = {};

            // process all fields to object
            angular.forEach(Object.keys(vm.form[form_type].field_model), function (field_name) {

                if (form_data[form_type].form_fields[field_name].template && !form_data[form_type].form_fields[field_name].params.readonly) {
                    switch (form_data[form_type].form_fields[field_name].template) {

                        case 'tags' :

                            var tags = vm.form[form_type].field_model[field_name];

                            form_submit_data[field_name] = [];

                            angular.forEach(tags, function (tag) {
                                form_submit_data[field_name].push(tag.text);
                            });

                            break;

                        case 'single_radio' :
                            form_submit_data[field_name] = vm.form[form_type].field_model[field_name] == null ? '' : vm.form[form_type].field_model[field_name];
                            break;

                        case 'checkbox':

                            form_submit_data[field_name] = [];
                            angular.forEach(vm.form[form_type].field_model[field_name], function (value, key) {
                                if (value != null && value != '') {
                                    form_submit_data[field_name].push(value);
                                }
                            })

                            break;
                        case 'date':

                            if (vm.form[form_type].field_model[field_name]) {
                                /**if (form_config.timezone) {
                                 form_submit_data[field_name] = moment(vm.form[form_type].field_model[field_name]).tz(form_config.timezone).format('YYYY-MM-DD');
                                 } else {
                                 form_submit_data[field_name] = moment(vm.form[form_type].field_model[field_name]).format('YYYY-MM-DD');
                                 }**/
                                form_submit_data[field_name] = moment(vm.form[form_type].field_model[field_name]).format('YYYY-MM-DD');
                            }

                            break;
                        case 'datetime':
                            if (vm.form[form_type].field_model[field_name]) {
                                //form_submit_data[field_name] = moment(vm.form[form_type].field_model[field_name]).tz(form_config.timezone).format('YYYY-MM-DD h:mm A');
                                form_submit_data[field_name] = moment(vm.form[form_type].field_model[field_name]).format('YYYY-MM-DD h:mm A');
                            }
                            break;
                        case 'select':

                            // multiple selections field
                            if (form_data[form_type].form_fields[field_name].params.type == 'multiple') {
                                form_submit_data[field_name] = [];

                                if (vm.form[form_type].field_model[field_name].selected && vm.form[form_type].field_model[field_name].selected.length) {
                                    angular.forEach(vm.form[form_type].field_model[field_name].selected, function (option, key) {
                                        form_submit_data[field_name].push(option.option_value);
                                    })
                                }
                            }

                            // single selection field
                            if (form_data[form_type].form_fields[field_name].params.type == 'single') {
                                if (vm.form[form_type].field_model[field_name] && vm.form[form_type].field_model[field_name].selected && vm.form[form_type].field_model[field_name].selected.option_value != null) {
                                    form_submit_data[field_name] = vm.form[form_type].field_model[field_name].selected.option_value;
                                } else if (vm.form[form_type].field_model[field_name] && !Array.isArray(vm.form[form_type].field_model[field_name].selected)) {
                                    form_submit_data[field_name] = vm.form[form_type].field_model[field_name].selected;
                                }
                            }

                            break;

                        case 'static-text':
                        case 'static-text-field':
                            // don't add to submit data
                            break;

                        default:

                            form_submit_data[field_name] = vm.form[form_type].field_model[field_name];

                            break;
                    }
                }

            });

            // combine any subfields
            angular.forEach(Object.keys(vm.form[form_type].field_model), function (field_name, key) {
                if (form_data[form_type].form_fields[field_name].template && !form_data[form_type].form_fields[field_name].params.readonly) {
                    switch (form_data[form_type].form_fields[field_name].template) {
                        case 'sub_field':
                            form_submit_data[field_name] = {};

                            angular.forEach(form_data[form_type].form_fields[field_name].sub_fields, function (field, key) {
                                form_submit_data[field_name][field.name] = form_submit_data[field.name];
                                delete form_submit_data[field.name];
                            });

                            break;
                    }
                }
            });

            // add parent_id if creating a child of an entity
            if (form_config.parent_id != null) {
                form_submit_data.parent_id = form_config.parent_id;
            }

            // add conference_id if creating event in a conference
            if (form_config.conference_id != null) {
                form_submit_data.conference_id = form_config.conference_id;
            }

            // add assignment data if adding faculty
            if (form_config.add_assignment) {
                form_submit_data.assignment = form_config.add_assignment
            }

            // add alternate value if pending faculty form
            if (form_config.faculty_type_to_add == 'pending'){
                form_submit_data.alternate = '1';
            }

            // add faculty type if exists
            if (form_config.faculty_type_to_add){
                form_submit_data.faculty_type = form_config.faculty_type_to_add;
            }

            console.log('TO SUBMIT', form_submit_data)

            $rootScope.$broadcast('formBeforeSubmit', {
                form_config: form_config,
                submit_data: form_submit_data
            });

            // submit data
            if (vm.form[form_type].data.sub_type == 'search') {

                FormsData.setSearchData(form_type, form_submit_data, form_data[form_type].form_fields);
                $rootScope.$broadcast('searchUpdated', {type: form_type});

            } else {

                switch (form_config.type) {
                    case 'external_user_search':

                        Users.searchExternalUser(form_submit_data).then(function (data) {
                            if (data && data.success) {

                                // set status message if no results found
                                if (!data.users.length) {
                                    data.message = 'No matching users found.';
                                }

                                completeFormSubmit(data);

                            } else if (data && !data.success) {
                                var show_error_msg = Utils.formatErrorMsg(data.message);

                                toaster.pop('error', 'Error', show_error_msg);
                                vm.form_submit_error = show_error_msg;
                            } else {
                                toaster.pop('error', 'Error', 'Form submit failed');
                                vm.form_submit_error = 'Server Error: Form submit failed';
                            }
                        })
                        break;
                    case 'campaign_schedule_send':

                        Campaigns.scheduleCampaign(form_config.id, form_submit_data).then(function (data) {
                            if (data && data.success) {

                                completeFormSubmit(data);

                            } else if (data && !data.success) {
                                var show_error_msg = Utils.formatErrorMsg(data.message);

                                toaster.pop('error', 'Error', show_error_msg);
                                vm.form_submit_error = show_error_msg;
                            } else {
                                toaster.pop('error', 'Error', 'Form submit failed');
                                vm.form_submit_error = 'Server Error: Form submit failed';
                            }
                        })
                        break;
                    case 'campaign_test' :
                        Campaigns.testCampaign(form_config.id, form_submit_data).then(function (data) {
                            if (data && data.success) {

                                $rootScope.alerts[form_type].push({type: 'success', msg: data.message});

                            } else if (data && !data.success) {
                                var show_error_msg = Utils.formatErrorMsg(data.message);

                                toaster.pop('error', 'Error', show_error_msg);
                                vm.form_submit_error = show_error_msg;
                            } else {
                                toaster.pop('error', 'Error', 'Form submit failed');
                                vm.form_submit_error = 'Server Error: Form submit failed';
                            }
                        })
                        break;

                    default:

                        Forms.updateForm(form_config, form_submit_data).then(function (data) {
                            if (data && data.success) {

                                FormsData.setLastCreatedItem(data.data);
                                $scope.returned_node = data.data;

                                // check if any files in queue and upload
                                // uploader will finish the final submit procedure
                                if ($scope.uploader && $scope.uploader.queue.length) {
                                    form_submit_return_data = data;

                                    // if new record, update the upload url and process file uploads
                                    // eg. banner upload after creating new conference, since we need the conference id to upload
                                    if (!form_config.id && !form_config.faculty_id) {
                                        vm.temp_upload_url = setUploadUrl(data.data.id);
                                        uploader.uploadAll();
                                    } else {
                                        // trigger upload
                                        uploader.uploadAll();
                                    }

                                } else {
                                    completeFormSubmit(data);
                                }

                            } else if (data && !data.success) {
                                var show_error_msg = Utils.formatErrorMsg(data.message);

                                toaster.pop('error', 'Error', show_error_msg);
                                vm.form_submit_error = show_error_msg;
                            } else {
                                toaster.pop('error', 'Error', 'Form submit failed');
                                vm.form_submit_error = 'Server Error: Form submit failed';
                            }
                        })

                        break;
                }
            }
        }
    }
]);