'use strict';

angular.module('app')
    .run(
    ['$http', '$cookies', '$rootScope', '$state', '$stateParams', 'AuthService','$location', '$localStorage',
        function ($http, $cookies, $rootScope, $state, $stateParams, Auth, $location, $localStorage) {
            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;

            $http.defaults.headers.post['X-CSRFToken'] = $cookies.csrftoken;

            $rootScope.disable_submit = false;

            $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {

                if (toState.name == 'print.assignments' || toState.name == 'print.agenda'){
                    $rootScope.print = true;
                }

                // Set last state
                if (toState.name != 'access.signin') {
                    $rootScope.returnToState = toState.name;
                    $rootScope.returnToStateParams = toParams;
                }

                if (toState.name == 'access.signin'){
                    $rootScope.app_section = 'login'
                } else {
                    $rootScope.app_section = '';
                }

                Auth.check().then(function (data) {

                    if (data && data.success) {

                        $localStorage['faculty_id'] = data.user.faculty_id;
                        $localStorage['user_id'] = data.user.id;

                        var roles = {};

                        angular.forEach(data.user.roles, function (value, key) {
                            roles[value] = true;
                        })

                        $rootScope.user = {
                            'full_name' : data.user.full_name,
                            'id' : null,
                            'roles' : roles
                        }

                        $rootScope.user_bg = {
                            'background-image' : 'url(/faculty/' + data.user.faculty_id + '/profile_photo)'
                        }

                        event.preventDefault();

                        /**if ($rootScope.returnToState) {
                            console.log('hello')

                            var to_state = $rootScope.returnToState;
                            var to_state_params = $rootScope.returnToStateParams;

                            $rootScope.returnToState = '';
                            $rootScope.returnToStateParams = '';

                            $state.go(to_state, to_state_params);
                        }**/
                    } else {

                        if (toState.authenticate) {
                            $state.go("access.signin");
                            event.preventDefault();
                        }
                    }
                });
            });
        }
    ]
)
    .config(
        ['$stateProvider', '$urlRouterProvider', 'JQ_CONFIG',
            function ($stateProvider, $urlRouterProvider, JQ_CONFIG) {

                $urlRouterProvider
                    .otherwise('/access/signin/');

                $stateProvider
                    .state('access', {
                        url: '/access',
                        templateUrl: 'app.html',
                    })
                    .state('access.maintenance', {
                        url: '/maintenance/',
                        templateUrl: 'maintenance.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/maintenance.js']).then(function () {return $ocLazyLoad.load(['toaster']);});
                                }]
                        },
                        authenticate: false
                    })
                    .state('access.signin', {
                        url: '/signin/',
                        templateUrl: 'access_signin.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/signin.js']).then(function () {return $ocLazyLoad.load(['toaster']);});
                                }]
                        },
                        authenticate: false
                    })
                    .state('access.signin_token_conference_coi_review_task', { // conference specific task id
                        url: '/signin_token/{token}/conference/{conference_id}/{section}/task/{task_id}',
                        templateUrl: 'access_signin.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/signin.js']).then(function () {return $ocLazyLoad.load(['toaster']);});
                                }]
                        },
                        authenticate: false
                    })
                    .state('access.signin_token_conference_coi_review_faculty', { // conference specific section and specific faculty id
                        url: '/signin_token/{token}/conference/{conference_id}/{section}/faculty/{faculty_id}',
                        templateUrl: 'access_signin.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/signin.js']).then(function () {return $ocLazyLoad.load(['toaster']);});
                                }]
                        },
                        authenticate: false
                    })
                    .state('access.signin_token_conference', { // conference specific section
                        url: '/signin_token/{token}/conference/{conference_id}/{section}',
                        templateUrl: 'access_signin.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/signin.js']).then(function () {return $ocLazyLoad.load(['toaster']);});
                                }]
                        },
                        authenticate: false
                    })
                    .state('access.signin_token', { // any section
                        url: '/signin_token/{token}/{section}',
                        templateUrl: 'access_signin.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/signin.js']).then(function () {return $ocLazyLoad.load(['toaster']);});
                                }]
                        },
                        authenticate: false
                    })
                    .state('app', {
                        abstract: true,
                        url: '/app',
                        templateUrl: 'app.html',
                    })
                    .state('app.home', {
                        url: '/home',
                        controller: 'HomeController',
                        templateUrl: 'views/home/index.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/home.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.profile', {
                        url: '/profile',
                        controller: 'ProfileController',
                        templateUrl: 'views/profile/index.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/profile.js']).then(function () {return $ocLazyLoad.load(['toaster','ui.select', 'ngImgCrop']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.areas_of_expertise', {
                        url: '/areas_of_expertise',
                        controller: 'AreasOfExpertiseController',
                        templateUrl: 'views/areas_of_expertise/index.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/areas_of_expertise.js']).then(function () {return $ocLazyLoad.load(['toaster','ui.select', 'ngImgCrop']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.disclosure', {
                        url: '/disclosure',
                        controller: 'DisclosureController',
                        templateUrl: 'views/disclosure.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/disclosure.js']).then(function () {return $ocLazyLoad.load(['toaster','ui.select']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.conferences', {
                        url: '/conferences',
                        controller: 'ConferencesController',
                        templateUrl: 'views/conferences/index.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/conferences.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    }).state('app.conferences_archive', {
                        url: '/conferences_archive',
                        controller: 'ConferencesArchiveController',
                        templateUrl: 'views/conferences/index.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/conferences_archive.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.conference_about', {
                        url: '/conference/{conference_id}/about',
                        controller: 'AboutController',
                        templateUrl: 'views/conferences/conference_about.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/conference_about.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.conference_coordinator', {
                        url: '/conference/{conference_id}/coordinator',
                        controller: 'CoordinatorController',
                        templateUrl: 'views/conferences/conference_coordinator.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/conference_coordinator.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.conference_notices', {
                        url: '/conference/{conference_id}/notices',
                        controller: 'NoticesController',
                        templateUrl: 'views/conferences/conference_notices.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/conference_notices.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.conference_tasks', {
                        url: '/conference/{conference_id}/tasks',
                        controller: 'TasksController',
                        templateUrl: 'views/conferences/conference_tasks.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/conference_tasks.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_day_availability', {
                        url: '/conference/{conference_id}/tasks/day_availability/{task_id}',
                        controller: 'TaskDayAvailabilityController',
                        templateUrl: 'views/conferences/tasks_day_availability.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_day_availability.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_presentation_release', {
                        url: '/conference/{conference_id}/tasks/presentation_release/{task_id}',
                        controller: 'TasksInfoReleaseController',
                        templateUrl: 'views/conferences/tasks_presentation_release.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_presentation_release.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    }) 
                    .state('app.tasks_profile_confirmation', {
                        url: '/conference/{conference_id}/tasks/profile_confirmation/{task_id}',
                        controller: 'TasksProfileController',
                        templateUrl: 'views/conferences/tasks_profile_confirmation.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_profile_confirmation.js']).then(function () {return $ocLazyLoad.load([
                                'toaster',
                                'ui.select',
                                'ngImgCrop'
                            ]);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_assignment_confirmation', {
                        url: '/conference/{conference_id}/tasks/assignment_confirmation/{task_id}',
                        controller: 'TasksAssignmentsController',
                        templateUrl: 'views/conferences/tasks_assignment_confirmation.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_assignment_confirmation.js']).then(function () {return $ocLazyLoad.load([
                                'angular.filter',
                                'angular-confirm',
                                'toaster',
                                'ui.select'
                            ]);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_disclosure_declaration', {
                        url: '/conference/{conference_id}/tasks/disclosure_declaration/{task_id}',
                        controller: 'TasksDisclosureController',
                        templateUrl: 'views/conferences/tasks_disclosure_declaration.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_disclosure_declaration.js']).then(function () {return $ocLazyLoad.load(['toaster','ui.select']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_planner_disclosure_declaration', {
                        url: '/conference/{conference_id}/tasks/planner_disclosure_declaration/{task_id}',
                        controller: 'TasksPlannerDisclosureController',
                        templateUrl: 'views/conferences/tasks_planner_disclosure_declaration.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_planner_disclosure_declaration.js']).then(function () {return $ocLazyLoad.load(['toaster','ui.select']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_hotel_form', {
                        url: '/conference/{conference_id}/tasks/hotel_form/{task_id}',
                        controller: 'TasksHotelController',
                        templateUrl: 'views/conferences/tasks_hotel_form.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_hotel_form.js']).then(function () {return $ocLazyLoad.load(['toaster','ui.select']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_specialties', {
                        url: '/conference/{conference_id}/tasks/specialties/{task_id}',
                        controller: 'TasksSpecialtyController',
                        templateUrl: 'views/conferences/tasks_specialties.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_specialties.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_areas_of_expertise', {
                        url: '/conference/{conference_id}/tasks/areas_of_expertise/{task_id}',
                        controller: 'TasksAreasOfExpertiseController',
                        templateUrl: 'views/conferences/tasks_areas_of_expertise.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_areas_of_expertise.js']).then(function () {return $ocLazyLoad.load([
                                'angular.filter',
                                'angular-confirm',
                                'toaster',
                                'ui.select'
                            ]);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_conflicts_of_interest', {
                        url: '/conference/{conference_id}/tasks/coi/{task_id}',
                        controller: 'TasksCoiController',
                        templateUrl: 'views/conferences/tasks_coi.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_coi.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_coi_review', {
                        url: '/conference/{conference_id}/tasks/coi_review/{task_id}',
                        controller: 'TasksCoiReviewController',
                        templateUrl: 'views/conferences/tasks_coi_review.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_coi_review.js']).then(function () {return $ocLazyLoad.load(['toaster','angularFileUpload']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_invitation_response', {
                        url: '/conference/{conference_id}/tasks/invitation_response/{task_id}',
                        controller: 'TaskInvitationResponseController',
                        templateUrl: 'views/conferences/tasks_invitation_response.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_invitation_response.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_invitation_response_initial', {
                        url: '/conference/{conference_id}/tasks/invitation_response_initial',
                        controller: 'TaskInvitationResponseInitialController',
                        templateUrl: 'views/conferences/tasks_invitation_response_initial.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_invitation_response_initial.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.tasks_planner_invitation_response', {
                        url: '/conference/{conference_id}/tasks/planner_invitation_response',
                        controller: 'TaskPlannerInvitationResponseController',
                        templateUrl: 'views/conferences/tasks_planner_invitation_response.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/tasks_planner_invitation_response.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })
                    .state('app.slide_invitation_response', {
                        url: '/slide_review/conference/{conference_id}/invitation_response',
                        controller: 'SlideReviewInvitationResponseController',
                        templateUrl: 'views/slide_review/slide_invitation_response.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['/js/controllers/slide_review_invitation_response.js'])
                                    .then(function () {
                                        return $ocLazyLoad.load(['toaster']);
                                    });
                            }]
                        },
                        authenticate: true
                    })
                    .state('app.slide_review', {
                        url: '/slide_review/conference/{conference_id}/faculty/{faculty_id}',
                        controller: 'SlideReviewController',
                        templateUrl: 'views/slide_review/slide_review.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['/js/controllers/slide_review.js'])
                                .then(function () {
                                    return $ocLazyLoad.load(['toaster']);
                                });
                            }]
                        },
                        authenticate: true
                    })
                    .state('app.slide_review_conferences', {
                        url: '/slide_review/conferences',
                        controller: 'SlideReviewConferencesController',
                        templateUrl: 'views/slide_review/index.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['/js/controllers/slide_review_conferences.js'])
                                    .then(function () {
                                        return $ocLazyLoad.load(['toaster']);
                                    });
                            }]
                        },
                        authenticate: true
                    })
                    .state('app.coi_review', {
                        url: '/coi_review/conference/{conference_id}/faculty/{faculty_id}',
                        controller: 'COIReviewController',
                        templateUrl: 'views/coi_review/coi_review.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {
                                return uiLoad.load(['/js/controllers/coi_review.js'])
                                    .then(function () {
                                        return $ocLazyLoad.load([
                                            'toaster',
                                            'ui.select',
                                            'xeditable',
                                        ]);
                                    });
                            }]
                        },
                        authenticate: true
                    })
                    .state('app.coi_review_conferences', {
                        url: '/coi_review/conferences',
                        controller: 'COIReviewConferencesController',
                        templateUrl: 'views/coi_review/index.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad', function (uiLoad, $ocLazyLoad) {return uiLoad.load(['/js/controllers/coi_review_conferences.js']).then(function () {return $ocLazyLoad.load(['toaster']);});}]
                        },
                        authenticate: true
                    })

                    .state('app.agenda_session', {
                        url: '/conference/{conference_id}/events/{event_id}',
                        controller: 'PrintAgendaController',
                        templateUrl: 'views/conferences/agenda_print.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/agenda_print.js']).then(
                                        function () {
                                            return $ocLazyLoad.load([
                                                'angular.filter',
                                                'angularMoment'
                                            ]);
                                        }
                                    );
                                }
                            ]
                        },
                        authenticate: true
                    })

                    .state('print', {
                        abstract: true,
                        url: '/print',
                        templateUrl: 'print.html',
                    })

                    .state('print.assignments', {
                        url: '/conference/{conference_id}/assignments',
                        controller: 'PrintAssignmentsController',
                        templateUrl: 'views/conferences/assignments_print.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/assignments_print.js']).then(
                                        function () {
                                            return $ocLazyLoad.load([
                                                'angular.filter',
                                                'angularMoment'
                                            ]);
                                        }
                                    );
                                }
                            ]
                        },
                        authenticate: true
                    })

                    .state('print.agenda', {
                        url: '/conference/{conference_id}/events/{event_id}',
                        controller: 'PrintAgendaController',
                        templateUrl: 'views/conferences/agenda_print.html',
                        resolve: {
                            deps: ['uiLoad', '$ocLazyLoad',
                                function (uiLoad, $ocLazyLoad) {
                                    return uiLoad.load(['/js/controllers/agenda_print.js']).then(
                                        function () {
                                            return $ocLazyLoad.load([
                                                'angular.filter',
                                                'angularMoment'
                                            ]);
                                        }
                                    );
                                }
                            ]
                        },
                        authenticate: true
                    })
            }
        ]
    );